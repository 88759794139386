@font-face {
  font-family: 'SFComicScript';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/SFComicScript.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/fonts/SFComicScript.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/fonts/SFComicScript.woff') format('woff'),
    /* Modern Browsers */
    url('/fonts/SFComicScript.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/fonts/SFComicScript.svg') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Diploma';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Diploma.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/fonts/Diploma.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/fonts/Diploma.woff') format('woff'),
    /* Modern Browsers */
    url('/fonts/Diploma.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/fonts/Diploma.svg') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Playbill';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/OPTILatin-Bold.ttf.eot') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('/fonts/OPTILatin-Bold.ttf.woff') format('woff'),
    /* Modern Browsers url('/fonts/Diploma.ttf') format('truetype'), */
    /* Safari, Android, iOS */
    url('/fonts/OPTILatin-Bold.ttfsvg') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Indubitably';
  font-style: normal;
  font-weight: 400;
  src:
    /* Modern Browsers  */
    url('/fonts/IndubitablyNF.ttf') format('truetype');
}

@font-face {
  font-family: 'PlaybillElongated';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/OPTILatin-Elongated.ttf.eot') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('/fonts/OPTILatin-Elongated.ttf.woff') format('woff'),
    /* Modern Browsers url('/fonts/Diploma.ttf') format('truetype'), */
    /* Safari, Android, iOS */
    url('/fonts/OPTILatin-Elongated.ttf.svg') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lobster-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pervitina';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Pervitina-Dex-FFP.ttf') format('truetype');
}

@font-face {
  font-family: 'Pervitina';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Pervitina-Dex-FFP.ttf') format('truetype');
}

@font-face {
  font-family: 'Cthulhumbus';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/JMH-Cthulhumbus-UG.ttf') format('truetype'),
    url('/fonts/JMH-Cthulhumbus.otf') format('opentype');
}

@font-face {
  font-family: 'Cthulhumbus-alt1';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/JMH-Cthulhumbus-UGalt1.ttf') format('truetype');

}

@font-face {
  font-family: 'Cthulhumbus-alt2';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/JMH-Cthulhumbus-UGalt2.ttf') format('truetype');
}

@font-face {
  font-family: 'CthulhusCalling';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Cthulhus-Calling.otf') format('opentype');
}